import { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { loginUser } from 'components/shared/globalFunctions';
import { checkUserAthentication } from '@/redux/thunks/customerThunks';
import { connect, useSelector } from 'react-redux';
import cookie from 'js-cookie';
import Gconfig from 'globalconfig';
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import jsCookie from 'js-cookie';
import EmailAlertPopup from 'components/shared/emailAlertPopup.js';
const LoginFormComp = (props) => {
    const systemSettings = useSelector(state => state.systemSettings)
    const [modal, setModal] = useState(false);
    const [userEmail, setUserEmail] = useState("")
    const [userPass, setUserPass] = useState("")
    // forgot password
    const [forgetPassword, setForgetPassword] = useState(false)
    const [isForgot, setIsForgot] = useState(false)
    const [loginCaptcha, setLoginCaptcha] = useState(false)
    const [forgetCaptcha, setForgetCaptcha] = useState(false)
    const [loginProcess, setLoginProcess] = useState(false)
    const [passNotFound, setPassNotFound] = useState(false)
    const [forgetError, setForgetError] = useState('')
    const [openAlertPopup, setOpenAlertPopup] = useState(false)
    const [alertCheck, setAlertCheck] = useState(true)
    useEffect(() => {
      if (passNotFound == true) {
        setTimeout(() => {
          setPassNotFound(false)
          setIsForgot(false)
        }, 16000)
  
      }
    }, [passNotFound])

    let sentDataloginUser = (tokenKeyCaptcha,fromPopup,updatedEmail) => {
      let loginFailAttempt = parseInt(jsCookie.get('loginFailAttempts'))
      let captchRetries = 0
      if(systemSettings.systemSettings && systemSettings.systemSettings.length > 0){
          systemSettings.systemSettings.filter(item => {
              if (item.setting_id == Gconfig.system_settings.loginCaptchaRetries) {
                  captchRetries = JSON.parse(item.setting_value)
              }
          })
      }
      if (loginFailAttempt && loginFailAttempt > captchRetries) {
        setLoginCaptcha(true)
        document.getElementById("errorMessageArea").innerHTML = '';
        return
      }
      let email = userEmail;
      let password = userPass;
      if(updatedEmail != ''){
        email= updatedEmail
      }
      if(userEmail&&userEmail.split('@')[1].indexOf('.con') > -1 && !fromPopup){
        if(userEmail.split('@')[1].indexOf('gmail.con') > -1){
            setOpenAlertPopup(true)
            setAlertCheck(false)
            return
        }else{
            let loginEmail= ''
            loginEmail= userEmail && userEmail.split('@')
            loginEmail= loginEmail[0]+'@'+loginEmail[1].replace('.con','.com')
            email= loginEmail
            setUserEmail(loginEmail)
        }
      }
      props._loginUser(email, password, setModal, setLoginProcess, setLoginCaptcha, setPassNotFound,tokenKeyCaptcha)
    }

    let sendForgotEmail = async () => {
      setForgetError('')
      await setForgetCaptcha(true)
    }

    let onChangeCaptcha = (token) => {
      jsCookie.set('loginFailAttempts', 0);
      setLoginCaptcha(false)
      sentDataloginUser(token,alertCheck,'');
    }

    let onChangeCaptchaforget = () => {
      setForgetCaptcha(false)
      setIsForgot(true)
      let customer_ip = cookie.get('customer_ip')
      let request_guid = jsCookie.get('request_guid')
      fetch(Gconfig.fpAPI + 'Customer/RequestPasswordReset?email=' + userEmail, {
        method: 'GET',
        headers: { 'X-Request-For':customer_ip, 'guid': request_guid}
      }).then(response => response.json()).then(data => {
        console.log(data)
        if (data.success) {
          setForgetPassword(false);
          setPassNotFound(true)
        } else {
          setForgetError(data.message)
        }
      })
    }

    let autoLogin = ()=> {
      if(window.innerWidth < 767 && userEmail != '' && !detectBrowser() && userPass != '') {
        setLoginProcess(true)
        sentDataloginUser('',alertCheck,'');
      }
    }
  
    let detectBrowser = ()=> {
      var isChromium = window.chrome;
      var winNav = window.navigator;
      var vendorName = winNav.vendor;
      var isOpera = typeof window.opr !== "undefined";
      var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
      var isIOSChrome = winNav.userAgent.match("CriOS");
  
      if (isIOSChrome) {
          // is Google Chrome on IOS
          console.log('is Google Chrome IOS');
          return true;
      } else if (
          isChromium !== null &&
          typeof isChromium !== "undefined" &&
          vendorName === "Google Inc." &&
          isOpera === false &&
          isIEedge === false
      ) {
          // is Google Chrome
          console.log('is Google Chrome');
          return true;
      } else {
          // not Google Chrome 
          console.log('not Google Chrome');
          return false
      }
    }
    let submitFromPopup = () => {
      setOpenAlertPopup(false)
      sentDataloginUser('',true,'')
    }
  
    let submitChangedEmail = () => {
      let loginEmail= ''
      setOpenAlertPopup(false)
      loginEmail= userEmail && userEmail.split('@')
      loginEmail= loginEmail[0]+'@'+loginEmail[1].replace('.con','.com')
      setUserEmail(loginEmail)
      sentDataloginUser('',true,loginEmail)
    }

    const Loader = () => {
      return(
        <div class="spinner_box spin_black">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
      )
    }

    let isDefaultPopup = props.isDefaultPopup || false;  

    return (
      <div>
           {
            !forgetPassword && 
              <form method="post" onSubmit={(e) => { sentDataloginUser('',false,''); e.preventDefault(); }}>
                   { isDefaultPopup ? 
                     <span className="loginpopupH1 d-block text-center">Welcome back!</span> :
                     <h6 className='text-uppercase heading'>sign in</h6> 
                    }
                    {loginCaptcha && !props.isGuestCheckout ? 
                    <div>
                        <p className='captcha_req_text text-left' > Captcha is required. </p>
                        <ReCAPTCHA className="captcha-load" sitekey={Gconfig.environment == 'production'?"6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK":'6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK'} onChange={onChangeCaptcha} />
                    </div> :
                    <div className="sign-form">
                      <div className='load_spin_box' >
                        <div id="errorMessageArea">error massage here</div>
                        {loginProcess == true && Loader()}
                      </div> 
                        <div className="form-group input-field ">
                            <label>EMAIL</label>
                            <input required="required" onBlur={()=>{autoLogin()}} autoFocus={true} name="email" required id="email" type="email" tabIndex="0" className={ isDefaultPopup ? "form-control" : "col-12 col-md-10 form-control"} value={userEmail} onChange={e => {setUserEmail(e.target.value)}} />
                        </div>
                        <div className="form-group input-field mb-2">
                            <label>PASSWORD</label>
                            <input required="required" required type="password" id="password" name="password" value={userPass} onChange={e => setUserPass(e.target.value)} className={ isDefaultPopup ? "form-control" : "col-12 col-md-10 form-control"} />
                        </div>
                        <span className={ isDefaultPopup ? "forgot-password-text" : "forgot-password"}><a id="forgot_password" onClick={() => { setForgetPassword(prevState => !prevState) }} >Forgot your password?</a></span>
                        {/* {loginCaptcha && <ReCAPTCHA className="captcha-load" sitekey={Gconfig.environment == 'production'?"6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK":'6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK'} onChange={onChangeCaptcha} />} */}
                        <div className={isDefaultPopup ? "text-center mt-4 mb-2" : "text-center mt-4 mb-2 col-12 col-md-10"}>
                            <button className="signin-button" type="submit" disabled={loginCaptcha && true}>
                              <span id="login-text">Sign In & Checkout</span>
                              {/* {loginProcess == true && <img className="loading-img-login" id="loading-img-login" src="/assets/img/new_loading_img_login.gif" />} */}
                              {/* {loginProcess == true && <Spinner className='spinner' />  } */}
                            </button>
                            <Modal isOpen={passNotFound} centered className="login-innerPopup">
                            <ModalBody>
                                <div className="text-center">
                                {isForgot != true && <span className="mb-3 d-block">We've made some site upgrades!<br />But we need you to create a new password.</span>}
                                <p>A <span style={{ color: '#E89CAE' }}><b>password reset link</b></span> was just sent to <strong>{userEmail}</strong>.</p>
                                <p>Please go<span style={{ color: '#E89CAE' }}> <b><u>check your email.</u></b></span></p>
                                <p>Then reset your password and sign in.</p>
                                </div>
                                {/* <button className="login-innerButton" onClick={() =>  setPassNotFound(false) }>ok</button> */}
                            </ModalBody>
                            </Modal>
                            
                        </div>
                        {/* <div id="errorMessageArea">error massage here</div> */}
                    </div> }
                </form>
                }

            {
            forgetPassword && 
            <div className="forgot_div">
              <div className="close login-modal" onClick={() => { setForgetPassword(prevState => !prevState) }} ><svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg></div>
              <span className={ isDefaultPopup ? "forgetpopupH1 d-block text-center" : "forgetpopupH1"}> Reset Your Password </span>
              {/* {forgetPasswordError == true ? <p className="mb-0" style={{ color: 'green', textAlign: 'center' }}> Forget Password Error.</p> : ''} */}
              <form method="post" onSubmit={(e) => { sendForgotEmail(); e.preventDefault(); }}>
                {forgetError != '' && <div className="forget-message">{forgetError}</div>}
                <div className="form-group input-field">
                  <label>Email</label>
                  <input required="required" name="forget_email" required id="forget_email" type="email" className={ isDefaultPopup ? "form-control" : "col-12 col-md-10 form-control"} value={userEmail} onChange={e => setUserEmail(e.target.value)} />
                </div>
                {/* 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI    (localhost recaptcha key)   */}
                {/* live key  6Ldnl3caAAAAAFWQ47KUfoE8iH2kVogG56ir9Nt8   */}
                {forgetCaptcha && <ReCAPTCHA className="captcha-load" sitekey={Gconfig.environment == 'production'?"6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK":'6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK'} onChange={onChangeCaptchaforget}></ReCAPTCHA>}
                <div className={ isDefaultPopup ? "text-center position-relative" : "text-center position-relative col-12 col-md-10"}>
                  <button type="submit" className='forgot-button'>
                    <span className="forgetSubmitButton"> Submit </span>
                  </button>
                </div>
              </form>
            </div>
            }
            {openAlertPopup && <EmailAlertPopup email={userEmail} submitForm={()=>{submitFromPopup()}} submitCorrectEmail={()=>{submitChangedEmail()}} isOpen={openAlertPopup} />}
        </div>
    )
}

  const mapDispatchToProps = (dispatch) => {
    return {
      _loginUser: (email, password, setModal, loginProcess, captcha,setForgetSuccess,tokenKeyCaptcha) => dispatch(loginUser(email, password, setModal, loginProcess, captcha,setForgetSuccess,tokenKeyCaptcha))
    }
  };
export default connect(null, mapDispatchToProps)(LoginFormComp);