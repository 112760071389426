import React from 'react';
import style from 'styles/_blackFriday.module.scss'
import { Modal, ModalBody } from 'reactstrap';

function EmailAlertPopup(props) {
    return (
        <div>
            <Modal isOpen={props.isOpen} size="lg" centered className={style.email_popup}>
                <ModalBody>
                    <div>
                        <p className={`${style.text_dec} mb-2`}><span>{props.email}</span></p>
                        <p className={style.text_dec}>Hey! It looks like you entered an <span>n</span> instead of <span>m</span><br/> to say <span>.con</span> instead of <span>.com</span></p>
                        <p className={style.text_dec}><span>Is this correct?</span></p>
                        <button onClick={()=>{props.submitCorrectEmail()}}>Change to .com</button>
                        <button onClick={()=>{props.submitForm()}}>Keep it as .con</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default EmailAlertPopup;