import React from 'react'

const WaveLoader = (size) => {
  // console.log(size, "size of loader")
    return(
      <div id="waveLoader" className={`spinner_box ${size.size == 'large' ? 'large_spinner_box' : ''}`} >
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
    )
  }

export default WaveLoader