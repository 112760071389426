import React from 'react';
// import {flushSync} from 'react-dom';
import { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, InputGroupText, InputGroup, Input } from 'reactstrap';
import { loginUser, closeHamburgerMenu } from 'components/shared/globalFunctions';
import WaveLoader from 'components/waveLoader'
import { checkUserAthentication } from '@/redux/thunks/customerThunks';
import { hideNotificationBar } from '@/redux/thunks/notificationThunks';
import { connect } from 'react-redux';
import cookie from 'js-cookie';
import Gconfig from 'globalconfig';
import jsHttpCookie from 'cookie';
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import jsCookie from 'js-cookie';
import LoginFormComp from 'components/shared/loginFormComp';
import Router from 'next/router';
import EmailAlertPopup from 'components/shared/emailAlertPopup.js';
const Login = (props) => {
  const [modal, setModal] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [userPass, setUserPass] = useState("")
  const [openLogin, setOpenLogin] = useState(false)
  // forgot password
  const [forgetPassword, setForgetPassword] = useState(false)
  const [isForgot, setIsForgot] = useState(false)
  const [loginCaptcha, setLoginCaptcha] = useState(false)
  const [forgetCaptcha, setForgetCaptcha] = useState(false)
  const [forgetPasswordError, setForgetPasswordError] = useState(false)
  const [loginProcess, setLoginProcess] = useState(false)
  const [forgetSuccess, setForgetSuccess] = useState(false)
  const [passNotFound, setPassNotFound] = useState(false)
  const [forgetError, setForgetError] = useState('')
  const [showPass, setShowPass] = useState(false);
  const [isMobile , setIsMobile] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false)
  const [alertCheck, setAlertCheck] = useState(true)
  const [forgotPassMessage, setForgotPassMessage] = useState(false)
  const fpPixel = "1481434321942616";
  let sentDataloginUser = async (tokenKeyCaptcha,fromPopup,updatedEmail) => {
   
    let loginFailAttempt = parseInt(jsCookie.get('loginFailAttempts'))
    let captchRetries = 0
    if(props.systemSettings.systemSettings && props.systemSettings.systemSettings.length > 0){
      props.systemSettings.systemSettings.filter(item => {
            if (item.setting_id == Gconfig.system_settings.loginCaptchaRetries) {
                captchRetries = JSON.parse(item.setting_value)
            }
        })
    }
    if (loginFailAttempt && loginFailAttempt > captchRetries) {
      setLoginCaptcha(true)
      // setForgotPassMessage(false)
      document.getElementById("errorMessageArea").innerHTML = '';
      return
    }
    let email = userEmail;
    if(updatedEmail != ''){
      email= updatedEmail
    }
    let password = userPass;
    if(userEmail&&userEmail.split('@')[1].indexOf('.con') > -1 && !fromPopup){
      if(userEmail.split('@')[1].indexOf('gmail.con') > -1){
          setOpenAlertPopup(true)
          setAlertCheck(false)
          return
      }else{
          let loginEmail= ''
          loginEmail= userEmail && userEmail.split('@')
          loginEmail= loginEmail[0]+'@'+loginEmail[1].replace('.con','.com')
          email= loginEmail
          setUserEmail(loginEmail)
      }
    }
    props._loginUser(email, password, setModal, setLoginProcess, setLoginCaptcha,setPassNotFound,tokenKeyCaptcha,props.fromSignupConfirmation)
    //  let msg = await props._loginUser(email, password, setModal, setLoginProcess, setLoginCaptcha,setPassNotFound,tokenKeyCaptcha)
    //  if(msg == 'Email or Password is incorrect!') {
    //     setForgotPassMessage(true)
    //   }
    //   else{
    //     setForgotPassMessage(false)
    //   }
    }
  let sendForgotEmail = async () => {
    setForgetError('')
    await setForgetCaptcha(true)
  }
  const {
    buttonLabel,
    className
  } = props;
  const toggle = (isClose) => {
    if (modal == true) {
      document.getElementsByTagName("body")[0].removeAttribute("style");
    }
    if (props.closeSideMenu) {
      closeHamburgerMenu()
    }
    setModal(!modal);
    if (!modal) {
      document.body.classList.add('no-scroll')
      if (props.openReviewPopup == true) {
        cookie.set('openReviewPopup', true);
      }
      setTimeout(function () {
        if (document.getElementById("email")) document.getElementById("email").focus();
      }, 200)
    } else {
      document.body.classList.remove('no-scroll')
      window.onscroll = function () { };
    }
    if (forgetPassword) {
      setForgetPassword(false)
    }
    let cookies = document.cookie;
    let cookiesJSON = jsHttpCookie.parse(cookies);
    if(isClose && cookiesJSON.customer_data && props.customer == "not_login" && !detectBrowser() && window.innerWidth < 767){
      jsCookie.set('loginPopupDisable', true);
    }

    if(props.isGuestCheckout != undefined &&  props.isGuestCheckout == true){
      props.toggleLogin()
    }
    if(props.fromHold != undefined && props.fromHold == true){
      if(jsCookie.get('fromProductSignup') != undefined){
        jsCookie.remove('fromProductSignup')
      }else{
        Router.push('/')
      }
    }

  }
  const spancolorb = {
    color: 'inherit'
  }
  useEffect(() => {
    if(modal == false){
      setTimeout(() => {
        document.body.classList.remove('no-scroll')
        window.onscroll = function () { };
        document.body.removeAttribute('style')
      }, 1000);
    
    }
   
  },[modal])

  useEffect(() => {
    if(props.isGuestCheckout != undefined && props.isGuestCheckout == true){
      if (props.guestEmail != undefined &&  props.guestEmail.length > 0){
        setUserEmail(props.guestEmail)
        setUserPass("")
      }
    }
    // console.log('aaaa')
  },[props.isGuestCheckout == true])

  useEffect(() => {
    if (passNotFound == true) {
      setTimeout(() => {
        setPassNotFound(false)
        setIsForgot(false)
      }, 16000)

    }
  }, [passNotFound])
  
  useEffect(() => {
    if (document.cookie.indexOf('passwordupdate=true') != -1) {
      document.cookie = "passwordupdate=false"
      setPasswordReset(true)
      setModal(true)
    }
    if(props.isOpen){
      setModal(true)
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let selectedPlan = params.get('selectedPlan') 
    let cookies = document.cookie;
    const cookiesJSON = jsHttpCookie.parse(cookies);
    let url = location.href
    if( url!= undefined && url.indexOf('local')==-1 && url.indexOf("dev")==-1&& url.indexOf("stage")==-1){
      if(params.get('selectedPlan') && localStorage.getItem('signUpFire') != 'true'){
        let isTeser = false;
        let emailUser = '' 
        if(params.get('openlogin') != undefined){
          emailUser = params.get('openlogin').toLowerCase();
          if(emailUser.indexOf('test')>-1){
              isTeser = true
              localStorage.setItem('signUpFire', 'true')
              setTimeout(()=>{
                localStorage.removeItem('signUpFire')
              },5000)
          }
          
        }else if (params.get('login') != undefined){
          emailUser = params.get('login')
          emailUser = atob(emailUser).split("(##)");
          if(emailUser[0].toLowerCase().indexOf('test')>-1){
             isTeser = true
             localStorage.setItem('signUpFire', 'true')
             setTimeout(()=>{
              localStorage.removeItem('signUpFire')
            },5000)
          }
           
        }
        // if(!isTeser){
        //   localStorage.setItem('signUpFire', 'true')
        //   // await fbFixelFireOnsignUp()
        // }
        
      }
    }
    if (params.get('wtlogin') != undefined && params.get('fname') != undefined) {
      let login = params.get('wtlogin');
      let fname = params.get('fname');
      fname = atob(fname);
      login = atob(login);
      login = login.split("(##)");
      let userEmail = login[0];
      let userPwd = login[1];
      console.log("coming from waitlist");
      console.log(userEmail, userPwd)
      console.log(fname)
      setLoginProcess(true)
      props._loginUser(userEmail, userPwd, '', setLoginProcess,setLoginCaptcha,'','',props.fromSignupConfirmation)
      if(jsCookie.get('fromProductSignup') != undefined){
        jsCookie.remove('fromProductSignup')
      }else{
        window.history.pushState('', '', '/');
      }
    }
    else if (params.get('openlogin') != undefined && openLogin == false && cookiesJSON.customer_token == undefined) {
      let email = params.get('openlogin');
      email = atob(email);
      setUserEmail(email)
      if (modal == false) {
        if(selectedPlan)
        selectedPlan = '?selectedPlan='+selectedPlan
        else
        selectedPlan = ''
        // await fbFixelFireOnsignUp('/'+Gconfig.collection_handle.clothing)
        window.history.pushState('', '', ''+window.location.pathname+selectedPlan);
        toggle();
      }
      setOpenLogin(true);
    } else if (params.get('openlogin') != undefined && openLogin == false && cookiesJSON.customer_token != undefined) {
      // await fbFixelFireOnsignUp('/' + Gconfig.collection_handle.clothing)
      if(selectedPlan)
        selectedPlan = '?selectedPlan='+selectedPlan
        else
        selectedPlan = ''
      window.history.pushState('', '', ''+window.location.pathname+selectedPlan);
    } else if (params.get('login') != undefined && openLogin == false && props.customer == "not_login") {
      let login = params.get('login')
      login = atob(login).split("(##)");
      setUserEmail(login[0])
      setUserPass(login[1])
      setLoginProcess(true)
      if (modal == false) {
        // await fbFixelFireOnsignUp(window.location.pathname)
        if(selectedPlan)
        selectedPlan = '?selectedPlan='+selectedPlan
        else
        selectedPlan = ''
        window.history.pushState('', '', window.location.pathname+selectedPlan);
        toggle();
        props._loginUser(login[0], login[1], setModal, setLoginProcess,setLoginCaptcha,'','',props.fromSignupConfirmation)
      }
    } else if (params.get('token') != undefined && location.pathname.indexOf('reset-password') == -1) {
      let token = params.get('token');
      jsCookie.set('customer_token', token);
      props._checkUserAthentication();
      if(jsCookie.get('fromProductSignup') != undefined){
        jsCookie.remove('fromProductSignup')
      }else{
        window.history.pushState('', '', '/');
      }
    }else if (cookiesJSON.customer_data && cookiesJSON.customer_data != 'not_login' && props.customer == "not_login" && !detectBrowser() && window.innerWidth < 767 && !jsCookie.get('loginPopupDisable')){
        // if(modal == false && props.isOpen == undefined){
        //   setModal(true)
        // }
    }else if (cookiesJSON.isWinBack){
        // setModal(true)
    } else{
      if(jsCookie.get('customer_data') == 'signOut'){
        jsCookie.set('customer_data', 'not_login');
      }
      if(params.get('login') != undefined && openLogin == false && props.customer != "not_login"){
        // await fbFixelFireOnsignUp()
        if(selectedPlan)
        selectedPlan = '?selectedPlan='+selectedPlan
        else
        selectedPlan = ''
        window.history.pushState('', '',''+window.location.pathname+selectedPlan);
        props._checkUserAthentication();
      }
    }
    document.addEventListener('keydown', (e) => {
      if (((e.key === "Delete" || e.key === "Backspace") && document.activeElement.classList.value == 'modal fade show') || (document.activeElement.getAttribute('data-name') == 'close' && e.key === "Enter")) {
        setModal(false);
        setTimeout(function () {
          document.body.classList.remove('no-scroll')
          window.onscroll = function () { };
        }, 200)
      }
    });
  }, [props.isOpen])
  let onChangeCaptcha = (value) => {
    jsCookie.set('loginFailAttempts', 0);
    setLoginCaptcha(false)
    sentDataloginUser(value, alertCheck,'');
  }
  let onChangeCaptchaforget = () => {
    setForgetCaptcha(false)
    setIsForgot(true)
    let customer_ip = cookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    fetch(Gconfig.fpAPI + 'Customer/RequestPasswordReset?email=' + userEmail, {
      method: 'GET',
      headers: { 'X-Request-For':customer_ip, 'guid': request_guid}
    }).then(response => response.json()).then(data => {
      console.log(data)
      if (data.success) {
        setForgetPassword(false);
        // toggle(false)
        // setForgetSuccess(true);
        setPassNotFound(true)
        // setTimeout(() => {
        //   setPassNotFound(false)
        //   setIsForgot(false)
        // }, 10000)
      } else {
        setForgetError(data.message)
      }
    })
  }
  let fbPixelFireOnsignUp = (urlLink)=>{
    let url = location.href
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let selectedPlan = params.get('selectedPlan') 
    // if( url!= undefined && url.indexOf('local')==-1 && url.indexOf("dev")==-1&& url.indexOf("stage")==-1){
      import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
          ReactPixel.init(fpPixel);
          // ReactPixel.pageView();
          if(url.indexOf("selectedPlan")!= -1){
            console.log('fp pixel fired -------------------->>>>>>')
              if(url.indexOf("socialite") != -1){
                  ReactPixel.trackCustom("successful-sign-up-socialite","");
                  ReactPixel.trackCustom("Successful-sign-up",{Plan: 'Socialite'});
              }
              if(url.indexOf("trendsetter") != -1){
                  ReactPixel.trackCustom("successful-sign-up-trendsetter","");
                  ReactPixel.trackCustom("Successful-sign-up",{Plan: 'TrendSetter'});
              }
              if(url.indexOf("wanderlust") != -1){
                  ReactPixel.trackCustom("successful-sign-up-wanderlust","");
                  ReactPixel.trackCustom("Successful-sign-up",{Plan: 'WanderLust'});
              }
              
              gtag('event', 'successful_sign_up', {  'transaction_id': '','type':'Plan_signup' });
              // setTimeout(()=>{
              //   localStorage.removeItem('signUpFire')
              // },5000)
              // ttq.track('Purchase-Submit-Email-Popup');
          }
          // window.history.pushState('', '', urlLink);
          // Router.events.on('routeChangeComplete', () => {
          // ReactPixel.pageView();
          // });
            // return true
      });
    
    // }
    // if(selectedPlan)
    // selectedPlan = '?selectedPlan='+selectedPlan
    // else
    // selectedPlan = ''
    // window.history.pushState('', '', urlLink+selectedPlan);
  }
  let autoLogin = ()=>{
    if(window.innerWidth < 767 && userEmail != '' && !detectBrowser() && userPass != '') {
      setLoginProcess(true)
      sentDataloginUser('',alertCheck,'');
    }
  }

  let detectBrowser = ()=> {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
        // is Google Chrome on IOS
        // console.log('is Google Chrome IOS');
        return true;
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
        // is Google Chrome
        // console.log('is Google Chrome');
        return true;
    } else {
        // not Google Chrome 
        // console.log('not Google Chrome');
        return false
    }
  }
  let togglePassEye = ()=>{
    setShowPass(!showPass)
  }
  useEffect(() => {
    if (window != undefined && window.screen.width < 767) {
      setIsMobile(true)
    }
  }, [])

  let submitFromPopup = () => {
    setOpenAlertPopup(false)
    sentDataloginUser('',true,'')
  }

  let submitChangedEmail = () => {
    let loginEmail= ''
    setOpenAlertPopup(false)
    loginEmail= userEmail && userEmail.split('@')
    loginEmail= loginEmail[0]+'@'+loginEmail[1].replace('.con','.com')
    setUserEmail(loginEmail)
    sentDataloginUser('',true,loginEmail)
  }
  let setPrevUrlForSignup=()=> {
    let url = location.pathname
    jsCookie.set('prevWebsiteUrl', url)
  }
  return ( 
    <React.Fragment>
      
        <span href="#" onClick={toggle} className={props.buttonClassName ? props.buttonClassName : "nav-link"} style={props.isSignup ? {display:"none"} : spancolorb}>{props.buttonName ? props.buttonName : 'Sign In'}</span>
        <Modal id="main-modal" isOpen={(modal || forgetPassword) && !forgetSuccess} toggle={ (((props.isGuestCheckout != undefined &&  props.isGuestCheckout == true) || props.fromSignupConfirmation == true ) ? undefined : toggle)} size="" aria-labelledby="contained-modal-title-vcenter" centered animation="false" className="login-modal">
          {loginCaptcha && <ModalHeader toggle={toggle}></ModalHeader> }
          <ModalBody className={loginCaptcha ? 'recaptcha_body':''} >
            {
              !forgetPassword && !forgetSuccess &&
              <>
              {loginCaptcha  ? 
               <div>
                  <p className='captcha_req_text' > Captcha is required. </p>
                 <ReCAPTCHA className="captcha-load" sitekey={Gconfig.environment == 'production'?"6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK":'6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK'} onChange={onChangeCaptcha} />
               </div>
              : 
              <div className={props.guestClassName == 'guest-checkout-login' ? "guest-checkout-login" : ''}>
                { ((props.isGuestCheckout != undefined && props.isGuestCheckout == true) || props.fromSignupConfirmation == true) ? '' : 
                 <div className="login-modal close" id="loginCloseBtn" title="Close" onClick={()=>toggle(true)}><svg xmlns="http://www.w3.org/2000/svg" data-name="close" version="1" tabIndex="-1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg></div>
                }
                {props.isGuestCheckout && isMobile ? <span className="loginpopupH1Mobile d-block text-center">sign in to your account</span> : <span className="loginpopupH1 d-block text-center">Welcome back!</span>}
                
                {!props.isGuestCheckout && <div className='log_loading_container' >
                  {<div id="errorMessageArea">error massage here</div> }
                  {loginProcess == true &&  <p> {Loader()} </p> }
                </div> }
                { props.isGuestCheckout && isMobile ?
                  <span className='fashionmember_text'>It seems like you already have an account, welcome back! Sign in for a quicker checkout, or continue as a guest. </span>
                  : ''}
                { props.isGuestCheckout && !isMobile ?
                  <>
                    <span className='fashionmember_text' > It seems like you're already a FashionPass member. </span>
                    <span className='signin_text' > Sign in to your account to access your exclusive membership discounts! </span>
                  </> : ''
                }

                {props.isGuestCheckout && <div className='log_loading_container' >
                  {<div id="errorMessageArea">error massage here</div> }
                  {loginProcess == true &&  <p> {Loader()} </p> }
                </div> }

                {loginCaptcha && <span className="captchamessage">Please complete the captcha to proceed.</span>}
                {passwordReset == true ? <p className="mb-0" style={{ color: 'green', textAlign: 'center' }}>Password updated successfully. Please log in with your new password.</p> : ''}
                <form method="post" onSubmit={(e) => { sentDataloginUser('',false,''); e.preventDefault(); }}>
                    <div className="form-group input-field">
                      <label>{props.isGuestCheckout && isMobile ? '' : 'EMAIL'}</label>
                      <input required="required" placeholder={props.isGuestCheckout && isMobile ? 'Email*' : ''} onBlur={()=>{autoLogin()}} autoFocus={true} name="email" required id="email" type="email" tabIndex="0" className="form-control" value={userEmail} onChange={e => {setUserEmail(e.target.value)}} />
                    </div>
                    <div className="form-group input-field mb-2">
                      {props.isGuestCheckout && isMobile ? '' : <label>PASSWORD</label>}
                      {props.isGuestCheckout ?
                        <InputGroup className='password-section'>
                          <Input type={showPass ? 'text' : 'password'} placeholder={props.isGuestCheckout && isMobile ? 'Password*' : ''} required="required" required id="password" name="password" value={userPass} onChange={e => setUserPass(e.target.value)} className="form-control" />
                          <InputGroupText>
                            <i className={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'} onClick={() => { togglePassEye() }}></i>
                          </InputGroupText>
                        </InputGroup>
                        : <input required="required" required type="password" id="password" name="password" value={userPass} onChange={e => setUserPass(e.target.value)} className="form-control" />
                      }
                      {/* {forgotPassMessage ? <div className="errorMessageForgot">Email or Password is incorrect! <br/> Need to reset your password? <span className="forgot-password-text"><a id="forgot_password" onClick={() => { setForgetPassword(prevState => !prevState) }} >Click Here</a></span> </div> : "" } */}
                      <span className="forgot-password-text"> <a id="forgot_password" onClick={() => { setForgetPassword(prevState => !prevState) }} > Forgot your password? </a> </span>
                    </div> 
                  
                  {loginCaptcha && <ReCAPTCHA className="captcha-load" sitekey={Gconfig.environment == 'production'?"6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK":'6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK'} onChange={onChangeCaptcha} />}
                  <div className="text-center position-relative">
                    <button className="login-btn" type="submit" disabled={loginCaptcha && true}>
                      {!props.isGuestCheckout  && <span id="login-text"> Log in </span>}
                      {props.isGuestCheckout  && <span id="login-text"> { `${ isMobile ? 'Sign in' : 'Sign In To Your Account'}`}</span>}
                    </button>
                    <Modal isOpen={passNotFound} centered className="login-innerPopup">
                      <ModalBody>
                        <div className="text-center">
                          {isForgot != true && <span className="mb-3 d-block">We've made some site upgrades!<br />But we need you to create a new password.</span>}
                          <p>A <span style={{ color: '#E89CAE' }}><b>password reset link</b></span> was just sent to <strong>{userEmail}</strong>.</p>
                          <p>Please go<span style={{ color: '#E89CAE' }}> <b><u>check your email.</u></b></span></p>
                          <p>Then reset your password and sign in.</p>
                        </div>
                      </ModalBody>
                    </Modal>
                    {/* {loginProcess == true && props.isGuestCheckout && <img className="loading-img-login" id="loading-img-login" src={Gconfig.fashionpassImageStorage+"assets/img/new_loading_img_login.gif?profile=a"} />} */}
                  </div>
                  {/* {props.isGuestCheckout && isMobile ? '' : <div id="errorMessageArea">error massage here</div>  } */}
                </form>
                { props.isGuestCheckout && !isMobile &&
                  <>
                    <div className='or_div' >
                      <span> OR </span>
                    </div>
                    <p className="newtag_text"> Specifically want a <span> "NEW WITH TAGS" </span> item? You must checkout as a guest using an alternate email address below. </p>
                  </>
                }
                { props.isGuestCheckout ?
                  <div className="guest-join-btn" onClick={()=>toggle()}>
                    {isMobile ? 'Continue as guest' : 'Continue With Alternate Email' }
                  </div>
                  :
                  <div className="join-link">
                    Don't have an account?<a href={Gconfig.waitlistURL} onClick={()=>{setPrevUrlForSignup()}}>Join Now</a>
                  </div> 
                }
                {/* {props.isGuestCheckout && isMobile ?  <div className='mt-2' id="errorMessageArea">error massage here</div> : ''} */}
              </div> 
              }
              </>
            }
            {
              forgetPassword && !forgetSuccess &&
              <div className={props.guestClassName == 'guest-checkout-login' ? "guest-checkout-login" : ''}>
                <div className="close login-modal" onClick={() => { setForgetPassword(prevState => !prevState) }} ><svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg></div>
                <span className="forgetpopupH1 d-block text-center"> Reset Your Password </span>
                {forgetPasswordError == true ? <p className="mb-0" style={{ color: 'green', textAlign: 'center' }}> Forget Password Error.</p> : ''}
                <form method="post" onSubmit={(e) => { sendForgotEmail(); e.preventDefault(); }}>
                  {forgetError != '' && <div className="forget-message">{forgetError}</div>}
                  <div className="form-group input-field">
                    <label>Email</label>
                    <input required="required" name="forget_email" required id="forget_email" type="email" className="form-control" value={userEmail} onChange={e => setUserEmail(e.target.value)} />
                  </div>
                  {forgetCaptcha && <ReCAPTCHA className="captcha-load" sitekey={Gconfig.environment == 'production'?"6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK":'6LduWRoiAAAAAMifAbymmq77nV-IYYZYHq6ZDIRK'} onChange={onChangeCaptchaforget}></ReCAPTCHA>}
                  <div className="text-center position-relative">
                    {/* {(props.isGuestCheckout && props.isGuestCheckout == true) ? 
                      <button className="guest-forgot-button" type="submit" > Submit </button> : */}
                      <button type="submit" className={(props.isGuestCheckout != undefined && props.isGuestCheckout == true ) ? 'guest-forgot-button' : ""}>
                        <span className="forgetSubmitButton"> Submit </span>
                      </button> 
                    {/* } */}
                  </div>
                </form>
              </div>
            }



          </ModalBody>
        </Modal>

      {/* <Modal id="msg-modal" isOpen={!modal && !forgetPassword && forgetSuccess} toggle={() => { setForgetSuccess(false) }} unmountOnClose={forgetSuccess} aria-labelledby="contained-modal-title-vcenter" centered animation="false" className="forgetSuccessModal">
       && forgetSuccess} toggle={() => { setForgetSuccess(false) }} unmountOnClose={forgetSuccess} aria-labelledby="contained-modal-title-vcenter" centered animation="false" className="forgetSuccessModal">
        <ModalBody>
          <div className="text-center">
            <p className="forgetSuccessText">An email was sent to <span className="email">{userEmail}</span> to reset your password.<span className="second">Please check your spam folder if it did not arrive within a couple of minutes.</span></p>
            <hr />
            <Button onClick={() => { setForgetSuccess(false) }} className="btn">ok</Button>
          </div>
        </ModalBody>
      </Modal> */}
      {openAlertPopup && <EmailAlertPopup email={userEmail} submitForm={()=>{submitFromPopup()}} submitCorrectEmail={()=>{submitChangedEmail()}} isOpen={openAlertPopup} />}
            
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    customer: state.customer.customer,
    systemSettings:state.systemSettings,
    
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    _loginUser: (email, password, setModal, loginProcess, captcha,setForgetSuccess,tokenKeyCaptcha,fromSignup) => dispatch(loginUser(email, password, setModal, loginProcess, captcha,setForgetSuccess,tokenKeyCaptcha,fromSignup)),
    _checkUserAthentication: () => dispatch(checkUserAthentication()),
    _hideNotificationBar: (obj) => dispatch(hideNotificationBar(obj)) 
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);

const Loader = () => {
  return(
    <WaveLoader size='small' />
  )
}